/* Buttons */
document.addEventListener('DOMContentLoaded', () => {
    // Select all links within the back button wrapper
    const backButtons = document.querySelectorAll('.is-style-back-button a');

    // Attach click event listeners to all back buttons
    backButtons.forEach(button => {
        button.addEventListener('click', (event) => {
            event.preventDefault(); // Prevent default link action
            window.history.back(); // Navigate to the previous page
            console.log('Clicked');
        });
    });
});







