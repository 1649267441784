// Ensure webpack processes the main scss file.
import '../scss/main.scss';

// Register plugins
gsap.registerPlugin(ScrollTrigger);

// Import core scripts
import './main/core/accessibility.js';

// Import animation scripts
import './main/animations/fade-ins.js';
import './main/ui/buttons.js';